import { AMBIENTE } from "../../environments";

// Api Backend
let API = "";
let INDEX = "";
let URL_ENTORNO = "";
let API_SEARCH_SEMANTICS = "";
const prod = "PROD";
const test = "TEST";
const dev = "DEV";

switch (AMBIENTE) {
    case prod:
        API = "https://admin.es.prod.ssociedades.nuvu.cc/";
        INDEX = "index_thesaurus";
        URL_ENTORNO = "https://tesauro.supersociedades.gov.co/";
        API_SEARCH_SEMANTICS = "https://bsemantica.prod.ssociedades.nuvu.cc:444/api/query/";
        break;
    case test:
        API = "https://admin.es.test.ssociedades.nuvu.cc/";
        INDEX = "index_thesaurus";
        URL_ENTORNO = "https://test.ssociedades.nuvu.cc/";
        API_SEARCH_SEMANTICS = "https://bsemantica.test.ssociedades.nuvu.cc:444/api/query/";
        break;
    case dev:
        API = "https://admin.es.test.ssociedades.nuvu.cc/";
        break;
    default:
        API = "https://public.test.sic.nuvu.cc/";
        break;
}

export const URL_AMBIENTE = URL_ENTORNO;
export const API_FILTERS = API + INDEX + "/_search?pretty";
export const API_DESCRIPTORES = API + "descriptores/_search?pretty";
export const API_SEARCH_SEMANTICA = API_SEARCH_SEMANTICS;

export const API_SEARCH_SEMANTICA_GETWAY =
    "https://e359gtzhce.execute-api.us-east-1.amazonaws.com/test/semanticsearch";

export const API_SEARCH_SEMANTICA_EC2 = "http://52.204.222.249:8080/api/query/";

export const API_SEARCH_SEMANTICA_SENTENCIA =
    "https://e359gtzhce.execute-api.us-east-1.amazonaws.com/test/searchsentencia";

export const API_COUNT = API + INDEX + "/_count";

export const API_INDEX = API + INDEX + "/_doc/";

export const API_ABSTRACT =
    "https://f6dr45n6z7.execute-api.us-east-1.amazonaws.com/test/parrafos-interes/extract";
// export const API_ABSTRACT = 'https://f6dr45n6z7.execute-api.us-east-1.amazonaws.com/test-2/parrafos-interes/extract';

// LV Values Types and Search Filter Types
export const NUMERO_EXPEDIENTE_KEY = "informacion.numero_expediente";
export const ANO_EXPEDIENTE_KEY = "informacion.numero_expediente";

export const PROCESS_TYPE_KEY = "informacion.tipo_proceso.keyword";
export const PROVIDENCE_TYPE_KEY = "informacion.tipo_providencia.keyword";
export const CONTENT_TYPE_KEY = "informacion.tipo_contenido";
export const CONTENT_TYPE_KEY_WORD = "informacion.tipo_contenido.keyword";
export const MATTERS_TYPE_KEY = "titulo.keyword";

export const PRINCIPAL_DESCRIPTOR_KEY =
    "descriptores.descriptor_principal.keyword";
export const SECOND_DESCRIPTOR_KEY =
    "descriptores.descriptores_secundarios.keyword";
export const FONTS_KEY = "fuentes_juridicas.fuente.keyword";
export const TEM_KEY = "informacion.tema.keyword";
export const THEME_PROBLEMS_KEY = "titulo.keyword";
export const PROBLEMS_KEY = "problemas_juridicos_temas.pregunta.keyword";

export const NORM_KEY = "archivos.entidades.texto";

export const ROL_KEY = "partes.rol.keyword";
export const ID_KEY = "partes.tipo_doc.keyword";
export const ID_NUMBER_KEY = "partes.numero_doc.keyword";
export const NAME_KEY = "partes.nombre";

export const END_DATE_KEY = "informacion.fecha_providencia";
export const START_DATE_KEY = "informacion.fecha_sentencia";
export const PUB_DATE_KEY = "informacion.fecha_publicada";
export const RADICADO_NUMBER_KEY = "informacion.numero_radicado";
export const CONSECUTIVO_NUMBER_KEY = "informacion.consecutivo";
export const PROCESO_NUMBER_KEY = "informacion.numero_proceso";

export const ID_REL_KEY = "id_relatoria.keyword";

export const HECHOS_RELEVANTES_DEMANDA_KEY =
    "analisis_estadistico.hechos_relevantes_demanda";
export const HECHOS_RELEVANTES_CONTESTACION_KEY =
    "analisis_estadistico.hechos_relevantes_contestacion_de_demanda";
export const PRETENSIONES_DEMANDA =
    "analisis_estadistico.pretensiones_presentadas_en_la_demanda";

export const KEYS_MAP = {
    processType: PROCESS_TYPE_KEY,
    providenceType: PROVIDENCE_TYPE_KEY,
    norms: NORM_KEY,
    rol: ROL_KEY,
    idType: ID_KEY,
    idNumber: ID_NUMBER_KEY,
    name: NAME_KEY,
    principalDescriptors: PRINCIPAL_DESCRIPTOR_KEY,
    secondDescriptors: SECOND_DESCRIPTOR_KEY,
    matter: MATTERS_TYPE_KEY,
    content: CONTENT_TYPE_KEY,
    font: FONTS_KEY,
    tem: TEM_KEY,
    hechosRelevantesDemanda: HECHOS_RELEVANTES_DEMANDA_KEY,
    hechosRelevantesContestacionDemanda: HECHOS_RELEVANTES_CONTESTACION_KEY,
    pretensionesDemanda: PRETENSIONES_DEMANDA,
    radicadoNumber: RADICADO_NUMBER_KEY,
    consecutivoNumber: CONSECUTIVO_NUMBER_KEY,
    procesoNumber: PROCESO_NUMBER_KEY,
    themeProblems: THEME_PROBLEMS_KEY,
    problems: PROBLEMS_KEY,
};

export const KEYS_BUSQUEDA_EXPEDIENTE = {
    anio: ANO_EXPEDIENTE_KEY,
    expediente: NUMERO_EXPEDIENTE_KEY,
};

export const RESULTS_PER_PAGE = 5;

export const SORT_OPTIONS = [
    {
        label: "Tipo de contenido (asc)",
        value: "CONTENT_TYPE_ASC",
        field: CONTENT_TYPE_KEY + ".keyword",
        order: "asc",
    },
    {
        label: "Tipo de contenido (desc)",
        value: "CONTENT_TYPE_DESC",
        field: CONTENT_TYPE_KEY + ".keyword",
        order: "desc",
    },
    {
        label: "Fecha (asc)",
        value: "DATE_ASC",
        field: START_DATE_KEY,
        order: "asc",
    },
    {
        label: "Fecha (desc)",
        value: "DATE_DESC",
        field: START_DATE_KEY,
        order: "desc",
    },
];

let URL_VISOR_AUX = "https://e359gtzhce.execute-api.us-east-1.amazonaws.com/test/configeraser";

switch (AMBIENTE) {
    case prod:
        URL_VISOR_AUX = "https://hhq68a8x0e.execute-api.us-east-1.amazonaws.com/prod/configeraser"
        break;
    case test:
        URL_VISOR_AUX = "https://e359gtzhce.execute-api.us-east-1.amazonaws.com/test/configeraser"
        break;
    default:
        break;
}
export const URL_VISOR = URL_VISOR_AUX;


let GACODE_AX = null;

switch (AMBIENTE) {
    case prod:
        GACODE_AX = "G-2HHX37RJTH"
        break;
    case test:
        GACODE_AX = "G-D3PMYSNMCN"
        break;
    default:
        break;
}
export const GACODE = GACODE_AX;


let SIGN_URL_AUX =
    "https://d5nqe7t9v5.execute-api.us-east-1.amazonaws.com/test/sign-url-upload/";
let SIGN_TRANSCRIPTION_URL_AUX =
    "https://d4jjve79qa.execute-api.us-east-1.amazonaws.com/test/sign-url-download/";
let UPLOAD_URL_AUX = "s3://ssociedades-test-relatorias-document/Document/";
let DOWNLOAD_URL_AUX =
    "s3://ssociedades-test-relatorias-transcription/Transcription/";

switch (AMBIENTE) {
    case prod:
        SIGN_URL_AUX =
            "https://hb7a4k9770.execute-api.us-east-1.amazonaws.com/prod/sign-url-upload/";
        SIGN_TRANSCRIPTION_URL_AUX =
            "https://hb7a4k9770.execute-api.us-east-1.amazonaws.com/prod/sign-url-download/";
        UPLOAD_URL_AUX = "s3://ssociedades-prod-relatorias-document/Document/";
        DOWNLOAD_URL_AUX = "s3://ssociedades-prod-relatorias-transcription/Transcription/";
        break;
    case test:
        SIGN_URL_AUX =
            "https://d5nqe7t9v5.execute-api.us-east-1.amazonaws.com/test/sign-url-upload/";
        SIGN_TRANSCRIPTION_URL_AUX =
            "https://d4jjve79qa.execute-api.us-east-1.amazonaws.com/test/sign-url-download/";
        UPLOAD_URL_AUX = "s3://ssociedades-test-relatorias-document/Document/";
        DOWNLOAD_URL_AUX =
            "s3://ssociedades-test-relatorias-transcription/Transcription/";
        break;
    default:
        break;
}
export const SIGN_URL = SIGN_URL_AUX;
export const SIGN_TRANSCRIPTION_URL = SIGN_TRANSCRIPTION_URL_AUX;
export const UPLOAD_URL = UPLOAD_URL_AUX;
export const DOWNLOAD_URL = DOWNLOAD_URL_AUX;

let CAPTCHA_API_KEY = "6Ldm6o8aAAAAANaK1UlUNX27tYB3bPBg1AG1NIfx";
// El captcha utilizado es versión 2, clave de sitio web en el código HTML  
switch (AMBIENTE) {
    case prod:
        CAPTCHA_API_KEY = "6LfZhogeAAAAACYt2J-DYOQy7F-MMpNXb--crxxO";
        break;
    case test:
        CAPTCHA_API_KEY = "6Ldm6o8aAAAAANaK1UlUNX27tYB3bPBg1AG1NIfx";
        break;
    case dev:
        CAPTCHA_API_KEY = "6LfrQwgaAAAAAC9nqAokIKf6iUnNzdZx-BSK9hDz";
        break;
    default:
        CAPTCHA_API_KEY = "6Ldm6o8aAAAAANaK1UlUNX27tYB3bPBg1AG1NIfx";
        break;
}

export const CAPTCHA_KEY = CAPTCHA_API_KEY;

const URL_SS_LIVE = "https://siis.ia.supersociedades.gov.co";
export let BACK_SIIS = ""

switch (AMBIENTE) {
    case prod:
        BACK_SIIS = `${URL_SS_LIVE}/siis_backend/api/v1/autenticacion/funcionario`;
        break;
    case test:
        BACK_SIIS = "https://e359gtzhce.execute-api.us-east-1.amazonaws.com/test/autenticacionldap"
        break;
    default:
        break;    
}

let LOG_BORRADOR_AUX = "https://vy59bjad24.execute-api.us-east-1.amazonaws.com/test/074_publicarborradorelastic";
let SEND_BORRADOR_SSOC_AUX = "https://9u3kvrkiv5.execute-api.us-east-1.amazonaws.com/test/075-insertarprocesotesauros";
let CITADO_EN_AUX = "https://f6dr45n6z7.execute-api.us-east-1.amazonaws.com/test/busqueda_citado";

switch (AMBIENTE) {
    case prod:
        LOG_BORRADOR_AUX = "https://hhq68a8x0e.execute-api.us-east-1.amazonaws.com/prod/publicar_borrador_elastic";
        SEND_BORRADOR_SSOC_AUX = "https://hhq68a8x0e.execute-api.us-east-1.amazonaws.com/prod/insertar_procesos_tesauro";
        CITADO_EN_AUX = "https://hhq68a8x0e.execute-api.us-east-1.amazonaws.com/prod/busqueda_citado";
        break;
    case dev:
        LOG_BORRADOR_AUX = "https://vy59bjad24.execute-api.us-east-1.amazonaws.com/test/074_publicarborradorelastic";
        SEND_BORRADOR_SSOC_AUX = "https://9u3kvrkiv5.execute-api.us-east-1.amazonaws.com/test/075-insertarprocesotesauros";
        CITADO_EN_AUX = "https://f6dr45n6z7.execute-api.us-east-1.amazonaws.com/test/busqueda_citado";
        break;
    default:
        break;    
}

export const LOG_BORRADOR = LOG_BORRADOR_AUX;
export const SEND_BORRADOR_SSOC = SEND_BORRADOR_SSOC_AUX;
export const CITADO_EN = CITADO_EN_AUX;

export const HIGHLIGHT = {
    fields: {
        "analisis_estadistico.excepciones_esgrimidas_contra_la_demanda": {},
        "analisis_estadistico.excepciones_esgrimidas_reconvencion": {},
        "analisis_estadistico.expediente_tribunal": {},
        "analisis_estadistico.fecha_segunda_instancia": {},
        "analisis_estadistico.hechos_relevantes_contestacion_de_demanda": {},
        "analisis_estadistico.hechos_relevantes_demanda": {},
        "analisis_estadistico.intervencion_terceros": {},
        "analisis_estadistico.juramento_estimatorio": {},
        "analisis_estadistico.parte_resolutiva_primera_instancia": {},
        "analisis_estadistico.parte_resolutiva_segunda_instancia": {},
        "analisis_estadistico.pretensiones_esgrimidas_reconvencion": {},
        "analisis_estadistico.pretensiones_presentadas_en_la_demanda": {},
        "analisis_estadistico.sentido_del_fallo_primera_instancia": {},
        "analisis_estadistico.sentido_del_fallo_segunda_instancia": {},
        "analisis_juridico.aclaracion_o_adicion": {},
        "analisis_juridico.consideraciones_adicionales": {},
        "analisis_juridico.problemas_juridicos": {},
        "analisis_juridico.rattio_decidendi": {},
        "analisis_juridico.segunda_instancia": {},
        "archivos.nombre": {},
        "archivos.ruta_s3": {},
        "borrador.keyword": {},
        "citas.estado": {},
        "citas.fuente": {},
        "citas.fuente_reforma": {},
        "citas.hipervinculo": {},
        "citas.tipo": {},
        "descriptores.descriptor_principal": {},
        "descriptores.descriptores_secundarios": {},
        "documento_principal.contenido_archivo": {},
        "documento_principal.metadatos": {},
        "documento_principal.ruta_s3": {},
        "etiquetas.Numero_relatoria": {},
        "etiquetas.aclaracion_o_adicion": {},
        "etiquetas.acumulacion_procesos": {},
        "etiquetas.analisis_estadistico": {},
        "etiquetas.analisis_juridico": {},
        "etiquetas.ano_expediente": {},
        "etiquetas.archivos": {},
        "etiquetas.citas": {},
        "etiquetas.consideraciones_adicionales": {},
        "etiquetas.correccion_sentencia": {},
        "etiquetas.descriptor_principal": {},
        "etiquetas.descriptores": {},
        "etiquetas.descriptores_secundarios": {},
        "etiquetas.documento_principal": {},
        "etiquetas.encargado_ficha_estadistica": {},
        "etiquetas.excepciones_esgrimidas_contra_la_demanda": {},
        "etiquetas.excepciones_esgrimidas_reconvencion": {},
        "etiquetas.expediente_tribunal": {},
        "etiquetas.fechaProvidencia": {},
        "etiquetas.fecha_publicacion_relatoria": {},
        "etiquetas.fecha_radicacion": {},
        "etiquetas.fecha_segunda_instancia": {},
        "etiquetas.fecha_sentencia": {},
        "etiquetas.fecha_ultima_modificacion": {},
        "etiquetas.ficha_estadistica": {},
        "etiquetas.ficha_juridica": {},
        "etiquetas.fuente_reforma": {},
        "etiquetas.fuentes_doctrinales": {},
        "etiquetas.fuentes_juridicas": {},
        "etiquetas.fuentes_jurisprudenciales": {},
        "etiquetas.fuentes_legales": {},
        "etiquetas.hechos_contestacion": {},
        "etiquetas.hechos_relevantes": {},
        "etiquetas.hechos_relevantes_contestacion_de_demanda": {},
        "etiquetas.hechos_relevantes_demanda": {},
        "etiquetas.id_archivo": {},
        "etiquetas.id_relatoria": {},
        "etiquetas.informacion": {},
        "etiquetas.informacion_suministrada": {},
        "etiquetas.intervencion_terceros": {},
        "etiquetas.juramento_estimatorio": {},
        "etiquetas.metadatos": {},
        "etiquetas.nombre_descriptor": {},
        "etiquetas.num_exp_tribunal": {},
        "etiquetas.num_postal": {},
        "etiquetas.numeroExpediente": {},
        "etiquetas.numeroRadicado": {},
        "etiquetas.numero_doc": {},
        "etiquetas.numero_ficha_estadistica": {},
        "etiquetas.numero_ficha_juridica": {},
        "etiquetas.numero_proceso": {},
        "etiquetas.numero_radicado": {},
        "etiquetas.parte_resolutiva_primera_instancia": {},
        "etiquetas.parte_resolutiva_segunda_instancia": {},
        "etiquetas.partes": {},
        "etiquetas.posicion_doctrinal_actual": {},
        "etiquetas.posicion_doctrinal_contraria": {},
        "etiquetas.pregunta": {},
        "etiquetas.pretensiones_demanda": {},
        "etiquetas.pretensiones_esgrimidas_reconvencion": {},
        "etiquetas.pretensiones_presentadas_en_la_demanda": {},
        "etiquetas.problemas_juridicos": {},
        "etiquetas.problemas_juridicos_temas": {},
        "etiquetas.rattio_decidendi": {},
        "etiquetas.reiteracion_doctrina": {},
        "etiquetas.ruta_s3": {},
        "etiquetas.sanciones_procesales": {},
        "etiquetas.segunda_instancia": {},
        "etiquetas.sentencia_oral": {},
        "etiquetas.sentido_del_fallo_primera_instancia": {},
        "etiquetas.sentido_del_fallo_segunda_instancia": {},
        "etiquetas.temas": {},
        "etiquetas.test": {},
        "etiquetas.tipoContenido": {},
        "etiquetas.tipo_contenido": {},
        "etiquetas.tipo_doc": {},
        "etiquetas.tipo_proceso": {},
        "fuentes_juridicas.actions": {},
        "fuentes_juridicas.estado": {},
        "fuentes_juridicas.fuente": {},
        "fuentes_juridicas.fuente_reforma": {},
        "fuentes_juridicas.hipervinculo": {},
        "fuentes_juridicas.tipo": {},
        "id_relatoria.keyword": {},
        "informacion.ano_expediente": {},
        "informacion.consecutivo": {},
        "informacion.fecha_sentencia": {},
        "informacion.fecha_ultima_modificacion": {},
        "informacion.normatividad": {},
        "informacion.numero_proceso": {},
        "informacion.numero_radicado": {},
        "informacion.tema": {},
        "informacion.tipo_contenido": {},
        "informacion.tramite": {},
        "metadatos.Antecedentes": {},
        "metadatos.Consideraciones": {},
        "metadatos.Costas": {},
        "metadatos.Hechos": {},
        "metadatos.Pretensiones": {},
        "metadatos.Resuelve": {},
        "partes.nombre": {},
        "partes.numero_doc": {},
        "partes.rol": {},
        "partes.tipo_doc": {},
        "problemas_juridicos_temas.posicion_doctrinal_actual": {},
        "problemas_juridicos_temas.posicion_doctrinal_contraria": {},
        "problemas_juridicos_temas.pregunta": {},
        "problemas_juridicos_temas.reiteracion_doctrina": {},
        "titulo.keyword": {},
    },
};

export const TRANSCRIPTION_TEST = `oficio 220-128136 del 09 septiembre de 2021

asunto: sociedad por acciones simplificada â articulo 4 ley 1258 de
2008 y otros temas.

me refiero a su comunicaciÃ³n radicada con el nÃºmero de la referencia, mediante la cual
solicita que se expira un concepto relacionado con los temas que a continuacion se
seÃ±alan:

âa. de acuerdo con lo seÃ±alado en la ley 1258 de 2008 y el cÃ³digo de comercio y la
legislaciÃ³n colombiana en materia financiera:

1. Â¢ existen restricciones respecto de la comercializaciÃ³n  de acciones de una
sas?
 2. Â¢ puede una sas publicitar ofertas privadas de acciones de las que hace referencia el
articulo 10 de la ley 1258 de 20087

3. Â¢puede una sas promocionar publicamente tales ofertas privadas de acciones a
posibles inversionistas?

4. ; puede una sas buscar inversionistas y/o posibles futuros accionistas publicamente o
por medios de amplia circulaciÃ³n?

5. Â¢ademas de las estatutarias, existen limitaciones para la oferta publica, negociaciÃ³n y
comercializaciÃ³n de acciones de una sas?

6. Â¢ puede una sas abrir  publicas, para futuros accionistas?

7. Â¢ puede recibir una sas  del pÃºblico para convertirse en futuro accionista?
8. Â¢(puede una sas poner en venta acciones a los postulantes publicos o posibles
inversionistas mediante una oferta privada?

b. al  para las sas de negociar valores en el mercado publico, es decir,
no poder inscribir acciones en el rave, ni negociar en bolsa.

1. ;puede la sas o sus administradores y/o representante legal, promocionar
publicamente las ofertas privadas de acciones en el mercado ?

2. Â¢existe alguna prohibiciÃ³n, restricciÃ³n o limitaciÃ³n para la comercializaciÃ³n de las
acciones de una sas fuera de la bolsa de valores?

3. Â¢ podrian negociar u ofrecerse a mas de 19 personas, las acciones de la sas?
c. respecto de la âcaptaciÃ³n ilegal de recursos del publicoâ¢"
"ofrezca publicamente acciones de una sas?
2. Â¢ puede tener una sas mas de 19 accionistas?

3. ;la invitacion publica a postularse para ser accionista o recibir oferta privada de
acciones con dividendo preferencial o dividendo fijo, puede ser considerada como
captaciÃ³n ilegal?

d. comercializaciÃ³n.

1. Â¢existen restricciones para que una persona natural y/o jurÃ­dica funda como
intermediario para la compraventa de acciones de una o varias sas, y los posibles
compradores de acciones, mediante ofertas privadas?

2. Â¢existen restricciones para una persona natural y/o jurÃ­dica habitualmente invite a
posibles inversionistas a postularse para suscribir acciones de sas mediante ofertas
privadas?

3. Â¢existen restricciones para una persona natural y/o jurÃ­dica dedique su actividad
comercial a la , busqueda de posibles inversionistas y negociaciÃ³n de
ofertas privadas de emisiÃ³n de acciones sas?

4. ; existen prohibiciones para la actividad de promocionar e invitar al publico a postularse
para recibir una oferta privada de emisiÃ³n de acciones de una sas o para convertirse en
accionista?

e. plataforma de comercio electrÃ³nico y finch.
en el marco de las plataformas de comercio electrÃ³nico y las finch:

1. Â¢ puede un desarrollo de este tipo, anunciar y ofertar la compraventa de acciones de
diferentes sas?

2. Â¢(puede un desarrollo de este tipo, servir como intermediaria entre posibles
inversionistas y las sas que venden sus acciones?

3. Â¢ puede un desarrollo de este tipo, ser plataforma transacciones para compraventa de
acciones de empresas sas e inversionistas?

4. ;puede un desarrollo de este tipo, ser lugar de encuentro tecnolÃ³gico entre posibles
inversionistas y vendedores de acciones de sas?
 5. Â¢ puede un desarrollo de este tipo, ser lugar de postulaciÃ³n de posibles inversionistas
para ser accionistas de las sas?
 6. Â¢puede un desarrollo de este tipo, buscar financiaciÃ³n, inversionistas o compradores
de acciones de diferentes sas, mediante la compraventa de sus acciones?

7. Â¢puede un desarrollo de este tipo, fingir como instrumento de busqueda de
oportunidades de inversion, mediante la postulaciÃ³n de posibles accionistas, para la
emisiÃ³n de ofertas privadas de acciones?

8. Â¢ puede un desarrollo de este tipo, fingir como plataforma de negociaciÃ³n de acciones
de sas emitidas mediante ofertas privadas?â"
"de consulta, la superintendencia de sociedades con fundamento en los articulos 14 y 28
de la ley 1437 de 2011, sustituido por el articulo 1Â° de la ley 1755 de 2015, emite un
concepto de caracter general y abstracto sobre las materias a su cargo, que no se dirige
a resolver situaciones de orden particular ni constituye asesora encaminada a solucionar
controversias, o determinar consecuencias jurÃ­dicas derivados de actos o decisiones de
los 6Ã³rganos de una sociedad determinada.

en este contexto, se explica que las respuestas en instancia consulta no son vinculantes
ni comprometen la responsabilidad de la entidad, como tampoco pueden condicional el
ejercicio de sus competencias administrativas o jurisdiccionales en un caso concreto.

adicionalmente, debe precisas que la entidad carece de competencia en funciÃ³n
consulta para resolver un caso concreto de competencia de las dependencias misiones
de la misma.

sus preguntas son de caracter particular y concreto, por lo que Ã©sta oficina no se referia
especificamente a las mismas; no obstante, atendiendo el derecho del consultarte a ser
orientado sobre el tema que le resulta de interÃ©s, se expondrÃ© algunas consideraciones
generales sobre el asunto.

1. en primer lugar, es preciso seÃ±alar que el articulo 4 de la ley 1258 de 2008 dispone lo
siguiente:

âarticulo 4o. imposibilidad de negociar valores en el mercado
publico. las acciones y los demas valores que emita la sociedad por acciones
simplificada no podran inscribirse en el registro nacional de valores y emisores ni
negociar en bolsa.â

sin embargo, el decreto legislativo 817 de 2020, âpor el cual se establecen las
condiciones especiales para la emisiÃ³n de valores en el segundo mercado por parte de
empresas afectadas en el marco del estado de emergencia econÃ³mica, social y ecolÃ³gica
declarado por el decreto numero 637 del 6 de mayo de 2020â, estableciÃ³ una excepcion
temporal a la prohibiciÃ³n contenida en seÃ±alado articulo cuarto.

las normas pertinentes del decreto legislativo 817 de 2020, establecen lo siguiente:

âarticulo 10. a partir de la expediciÃ³n de este decreto legislativo y hasta por
dos adios calendario, las emisiones de valores que se realicen en el segundo
mercado atenderÃ¡n, ademas de las normas vigentes, las disposiciones del presente
decreto.

articulo 20. durante el tÃ©rmino al que se refiere el articulo 1o del presente
decreto legislativo, los titulos representativo de deuda que las sociedades por "
"mercado podran inscribirse en el registro nacional de valores y emisores (rave)
y negociar en una bolsa de valores. el plazo maximo de estas emisiones sera de
hasta cinco (5) aÃ±os.

el gobierno nacional determinar las condiciones estatutarias y de gobierno
corporativo para que las sociedades a las que se refiere este articulo accedan al
mercado de valores.

articulo 3o. las emisiones de que trata el presente decreto legislativo podran
ser garantizadas por el fondo nacional de garantÃ­as s. a., en los tÃ©rminos que
establezcan sus estatutos y demas normas aplicables.â

la emisiÃ³n y colocaciÃ³n de acciones constituye un mecanismo de capitalizacion de la
sociedad, encaminado a la coneccion de recursos para la realizaciÃ³n del objeto social
de la compaÃ±ia, sin que la emisiÃ³n de acciones pueda construirse en si misma en la
actividad econÃ³mica de la sociedad, como al parecer podria inferiores de algunos de los
interrogantes planteado en el punto a de su escrito, lo cual no resulta 
viable.

en lo que corresponde a las demas preguntas relacionadas con el literal a de su escrito,
sobre las actividades que estan orientadas a publicitar ofertas privadas de acciones, sin
actuar en el mercado publico de valores, deber verificar en cada caso particular que
se cumpla con la  en materia societaria y que no se  las normas que
prohiben la captaciÃ³n ilegal de dineros del publico.

2. en cuanto a la forma de emisiÃ³n de acciones por parte de las s.a.s., aspecto al que se
contrae el literal b de su consulta, y sin perjuicio de las normas referidas en el punto
anterior, en especial las tientes a la excepcion temporal a la prohibiciÃ³n del articulo 4 de
la ley 1258 de 2008, conviene traer a colaciÃ³n lo dicho por Ã©sta superintendencia en el
oficio 220-087094 del 2 de agosto de 2009. asunto: emisiÃ³n y colocaciÃ³n de
acciones y demas valores en sociedades por acciones simplificadas:

â3. Â¢(las s.a.s. para colocar las acciones u otros valores que emitan pueden hacerlo a
travÃ©s de una oferta publica? o a travÃ©s de una oferta privada? ;cual seria la
regulaciÃ³n aplicable?â en este punto es preciso traer a colaciÃ³n lo que acerca del presente
cuestionamiento manifestÃ³ la superintendencia financiera, delegatura para emisores,
portafolios de inversiÃ³n y otros agentes, en oficio del 17 de junio de 2009, radicacion
numero 2009030292-004, a saber: â sobre el particular, el articulo 4Â° de la ley 1258 de
2008 establece que, â las acciones y los demas valores que emita la sociedad por
acciones simplificada no podran inscribirse en el registro nacional de valores y emisores
ni negociar en bolsaâ , lo que implica que las sas no podran realizar oferta publica en
el mercado de valores de sus titulos emitidos ni tratarlos en un sistema de negociaciÃ³n,
dado que el articulo 1.1.2.1. de la resoluciÃ³n 400 de 1995, estipula que para ello las "
"forma, si una emisiÃ³n de valores de las sas llegara a configurar una oferta pÃºblica de
valores en los tÃ©rminos de la resoluciÃ³n 400, la sociedad debera desistir de efectuar en
razÃ³n a que no tiene ni la capacidad legal ni la autorizaciÃ³n para hacerlo.

  lr an ty l tl ) gl b  lay me rob o en um e tal lr

es del caso precisar que cualquier oferta publica de valores requiere de la previa
autorizacion de la superintendencia financiera de colombia en los tÃ©rminos del articulo
104 del decreto 2150 de 1995, y que en caso contrario estas seran ineficaces de pleno
derecho, sin perjuicio de las sanciones administrativas que se puedan imponer por tal
hecho, segÃºn lo establece el articulo 49 y siguientes de la ley 964 de 2005.

asi las cosas, cabe precisar que los valores que emitan las sas solo podran ser objeto de
oferta privada, es decir, aquella que se dirÃ­a a noventa y nueve (99) personas
determinadas o a los mismos accionistas de la empresa, pero cuando estos sean menos
de quinientos (500), (...)". del pronunciamiento que intercede, es claro que en razÃ³n a que
la sociedad por acciones simplificada no puede inscribir sus acciones y demas valores en
el registro nacional de valores y emisores (articulo 4Â° ley 1258 de 2008), la misma no
puede realizar oferta pÃºblica de acciones ni de otros valores, pues para tal efecto se
requiere precisamente haber inscrito los titulos respectivos en el mencionado registro. de
esta suerte, los valores que emita una sociedad por acciones simplificada solo podrÃ­an
colocarse mediante oferta privada...â

lo anterior, sin perder de vista que en todo momento se deben respetar las normas que
prohiben la captaciÃ³n ilegal de dineros del publico.

3. en oficio 220-006288 del 8 de febrero de 2019, Ã©ste despacho se pronuncio respecto
de los hechos constitutivo de captaciÃ³n de dineros del publico en forma masiva y habitual,
inclusive cuando la misma se ha producido de manera directa o indirecta, concepto que
se sugiere revisar.

4. el literal d de su consulta fue resuelto con la respuesta del punto a.

5. en lo que corresponde al punto e, se observa que en torno a estas plataformas de
comercio electrÃ³nico y las finch, mediante el oficio 220-117618 del 20 de agosto del
presente aÃ±o, Ã©sta oficina en particular hizo alusiÃ³n a la figura del , que
corresponde a una modalidad de financiaciÃ³n empresarial, que:

â(...) surge como importante opcion de apoyo al emprendimiento, alternativa a los
mecanismos formales de financiamiento bancario los que, para el caso de las ,
las mas de las veces, resultan asequibles por sus costos o por la excesiva exigencia de
formalidades o garantÃ­as.

1 superintendencia de sociedades. oficio no. 220-006288 (8 de febrero de 2019). asunto: captaciÃ³n ilegal de recursos
del pÃºblico â decreto 4334 de 2008. {en linea}. {18/09/2020}. disponible en:
hits://wow.supersociedades.go.co/nuestra_entidad//_conceptos_jurÃ­dicos/oficio_220-
006288_de_2019.puf "
"encuentra prevista por la ley para ser adelantada por sociedades del tipo de las anÃ³nimas
especializadas en este tipo de , bolsas de valores y sistemas de
negociaciÃ³n o registro de valores, todas Ã©stas vigiladas por la superintendencia
financiera de colombia. se encuentra prevista para que los importantes reciban a cambio
de su colaboraciÃ³n ya sea valores que representen una deuda a su favor y a cargo de la
compaÃ±ia receptora, o valores que representen la participacion del importante en el capital
social de la compania.

tÃ©ngame en cuenta que el mecanismo se encuentra diseÃ±ado para que la compania de
 participe, siempre, como intermediaria. legalmente no se previo la
posibilidad que a travÃ©s de su plataforma busque  de parte de
importantes del publico.

en este punto, valga advertir que, en caso de que una compaÃ±ia capte dineros del publico
a travÃ©s de una plataforma tecnolÃ³gica con fines de  para obtener capital
de trabajo, ofreciendo como contraprestacion la devoluciÃ³n del dinero o la de Ã©ste junto
con algun tipo de rendimiento sin prever como contraprestacion el suministro de bienes o
servicios, podria incurrir en captaciÃ³n masiva no autorizada de dineros del publico,
actividad ilÃ­cita que acarrea la intervenciÃ³n del sujeto captador por parte de esta
superintendencia...â

de conformidad con lo expuesto, se responde de manera cabal la consulta, teniendo como
base fundamental los conceptos referidos en cada item particular, no sin antes reiterar que
los efectos del presente pronunciamiento son los descritos en el articulo 28 de la ley 1437
de 2011, sustituido por el articulo 1Â° de la ley 1755 de 2015 y que en la pagina web de
Ã©sta entidad puede consultar directamente la , los conceptos que la misma
emite sobre las materias de su competencia y la circular basica jurÃ­dica, entre otros.`;

export const KEYS_MAP_TRANSLATE = {
    processType: "Tipo de proceso",
    providenceType: "Tipo providencia",
    norms: "Normas",
    rol: "Rol",
    idType: "Tipo identificación",
    idNumber: "Número identificación",
    name: "Nombre",
    principalDescriptors: "Descriptor principal",
    secondDescriptors: "Descriptor secundario",
    matter: "Asunto",
    content: "Tipo de contenido",
    font: "Fuente",
    tem: "Tema",
    hechosRelevantesDemanda: "Hechos relevantes demanda",
    hechosRelevantesContestacionDemanda: "Hechos relevantes contestación",
    pretensionesDemanda: "Pretensiones demanda",
    radicadoNumber: "Radicado",
    consecutivoNumber: "Consecutivo",
    procesoNumber: "Proceso",
    themeProblems: "Tema",
    problems: "Problema",
    startDate: "Fecha inicial",
    endDate: "Fecha final",
};
